.container {
  width: 70%;
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Antonio, sans-serif;
  font-size: 18px;
  letter-spacing: 3px;
  text-align: center;
  color: #565656;
  font-weight: 400;

  .info {
    letter-spacing: 2px;
    font-weight: 100;
  }
}